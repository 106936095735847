export function LanguageDropdown({
  language,
  excludeLanguage,
  short,
  setLanguage,
}: {
  language: string;
  excludeLanguage: string | undefined;
  short: boolean;
  setLanguage: (value: string) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
  };

  return (
    <select value={language} onChange={handleChange}>
      {excludeLanguage !== "en" && (
        <option value="en">{short ? "🇺🇸" : "🇺🇸 English"}</option>
      )}
      {excludeLanguage !== "de" && (
        <option value="de">{short ? "🇩🇪" : "🇩🇪 German"}</option>
      )}
      {excludeLanguage !== "ru" && (
        <option value="ru">{short ? "🇷🇺" : "🇷🇺 Russian"}</option>
      )}
    </select>
  );
}
