export interface LocalizationStrings {
  loading_story_list: string;
  loading_story_list_error: string;
  select_story: string;
  loading_story: string;
  loading_story_error: string;
  story_not_found_error: string;
  loading_explain: string;
  loading_explain_error: string;
  settings_title: string;
  settings_i_speak: string;
  settings_i_learn: string;
  close_button_caption: string;
}

const strings = new Map<string, LocalizationStrings>([
  [
    "en",
    {
      loading_story_list: "Loading story list...",
      loading_story_list_error: "Error loading story list",
      select_story: "Select a story",
      loading_story: "Loading story...",
      loading_story_error: "Error loading story",
      story_not_found_error: "Story not found :(",
      loading_explain: "Loading explanation...",
      loading_explain_error: "Error loading explanation",
      settings_title: "Settings",
      settings_i_speak: "I speak:",
      settings_i_learn: "I learn:",
      close_button_caption: "✗ Close",
    },
  ],
  [
    "de",
    {
      loading_story_list: "Lade Geschichtenliste...",
      loading_story_list_error: "Fehler beim Laden der Geschichtenliste",
      select_story: "Wähle eine Geschichte",
      loading_story: "Lade Geschichte...",
      loading_story_error: "Fehler beim Laden der Geschichte",
      story_not_found_error: "Geschichte nicht gefunden :(",
      loading_explain: "Lade Erklärung...",
      loading_explain_error: "Fehler beim Laden der Erklärung",
      settings_title: "Einstellungen",
      settings_i_speak: "Ich spreche:",
      settings_i_learn: "Ich lerne:",
      close_button_caption: "✗ Schließen",
    },
  ],
  [
    "ru",
    {
      loading_story_list: "Загрузка списка историй...",
      loading_story_list_error: "Ошибка загрузки списка историй",
      select_story: "Выберите историю",
      loading_story: "Загрузка истории...",
      loading_story_error: "Ошибка загрузки истории",
      story_not_found_error: "История не найдена :(",
      loading_explain: "Загрузка объяснения...",
      loading_explain_error: "Ошибка загрузки объяснения",
      settings_title: "Настройки",
      settings_i_speak: "Я говорю на:",
      settings_i_learn: "Я учу:",
      close_button_caption: "✗ Закрыть",
    },
  ],
]);

export function lstr(locale: string): LocalizationStrings {
  const s = strings.get(locale);
  if (!s) {
    console.error("Unknown localization locale", locale);
    return strings.get("en")!;
  }
  return s;
}
