import { useEffect, useRef } from "react";
import { lstr } from "./localization";

export function Modal({
  preferedWidth,
  showCloseButton,
  locale,
  closeModal,
  children,
}: {
  preferedWidth: number;
  showCloseButton: boolean;
  locale: string;
  closeModal: () => void;
  children: React.ReactNode;
}) {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    ref.current?.showModal();
  });

  return (
    <dialog
      className={`rounded-xl max-w-[90%] max-w-[600px] min-w-[300px] bg-white shadow-lg`}
      ref={ref}
      onCancel={closeModal}
    >
      {children}
      {showCloseButton && (
        <div className="flex justify-center">
          <button
            className="p-4 text-xl font-semibold text-gray-600"
            onClick={closeModal}
          >
            {lstr(locale).close_button_caption}
          </button>
        </div>
      )}
    </dialog>
  );
}
