import { Settings } from "./settings";
import ShowTranslationCheckbox from "./ShowTranslationCheckbox";
import { IoSettingsOutline, IoMenuOutline } from "react-icons/io5";

export function TopMenu({
  showStoryListButton,
  settings,
  setSettings,
  setShowSettings,
  setShowStoryList,
}: {
  showStoryListButton: boolean;
  settings: Settings;
  setSettings: (value: Settings) => void;
  setShowSettings: () => void;
  setShowStoryList: () => void;
}) {
  return (
    <div className="top-0 left-0 h-[44px] w-full flex justify-center text-xl px-4 bg-[#333333] z-50">
      <div
        className={`w-full max-w-[650px] flex ${showStoryListButton ? "justify-between" : "justify-end"}`}
      >
        {showStoryListButton && (
          <button
            type="button"
            onClick={setShowStoryList}
            className="flex items-center justify-center h-[44px] w-[44px] text-[32px] text-[#F8F3E6] font-semibold"
          >
            <IoMenuOutline className="inline" />
          </button>
        )}
        {showStoryListButton && (
          <ShowTranslationCheckbox
            isChecked={settings.showTranslation}
            setIsChecked={(value: boolean) =>
              setSettings({ ...settings, showTranslation: value })
            }
          />
        )}
        <button
          type="button"
          onClick={setShowSettings}
          className="flex items-center justify-center h-[44px] w-[44px] text-[24px] text-[#F8F3E6] font-semibold"
        >
          <IoSettingsOutline className="inline" />
        </button>
      </div>
    </div>
  );
}
