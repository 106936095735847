import { useStoryListQuery } from "./queries";
import { lstr } from "./localization";
import { Modal } from "./Modal";

export function StoryMenu({
  l,
  r,
  currentStoryId,
  onStorySelected,
}: {
  l: string;
  r: string;
  currentStoryId: string | null;
  onStorySelected: (storyId: string) => void;
}) {
  const query = useStoryListQuery(l, r);

  if (query.isPending) {
    return <div>{lstr(l).loading_story_list}</div>;
  }

  if (query.isError) {
    return <div>{lstr(l).loading_story_list_error}</div>;
  }

  return (
    <div className="w-full overflow-auto">
      {query.data.map((story) => (
        <button
          key={story.id}
          onClick={() => onStorySelected(story.id)}
          className={
            `block text-left w-full p-2 hover:bg-blue-100` +
            (story.id === currentStoryId
              ? " bg-blue-100 rounded-lg font-semibold"
              : "")
          }
        >
          <div className="flex justify-left">
            <div className="min-w-[50px]">{story.level}</div>
            <div className="underline">{story.title}</div>
          </div>
        </button>
      ))}
    </div>
  );
}

export function StoryMenuModal({
  l,
  r,
  currentStoryId,
  onStorySelected,
  closeModal,
}: {
  l: string;
  r: string;
  currentStoryId: string | null;
  onStorySelected: (storyId: string) => void;
  closeModal: () => void;
}) {
  const selectStoryAndClose = (storyId: string) => {
    onStorySelected(storyId);
    closeModal();
  };

  return (
    <Modal
      showCloseButton={true}
      locale={l}
      preferedWidth={500}
      closeModal={closeModal}
    >
      <div className="bg-white p-4">
        <StoryMenu
          l={l}
          r={r}
          currentStoryId={currentStoryId}
          onStorySelected={selectStoryAndClose}
        />
      </div>
    </Modal>
  );
}

export default StoryMenu;
