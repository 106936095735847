import { Modal } from "./Modal";
import { Settings } from "./settings";
import { LanguageDropdown } from "./LanguageDropdown";
import { lstr } from "./localization";

function SettingsMenu({
  settings,
  setSettings,
}: {
  settings: Settings;
  setSettings: (value: Settings) => void;
}) {
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="min-w-[100px]">
            {lstr(settings.lLocale).settings_i_speak}
          </div>
          <LanguageDropdown
            language={settings.lLocale}
            excludeLanguage={undefined}
            short={true}
            setLanguage={(value: string) =>
              setSettings({ ...settings, lLocale: value })
            }
          />
        </div>
        <div className="flex gap-2">
          <div className="min-w-[100px]">
            {lstr(settings.lLocale).settings_i_learn}
          </div>
          <LanguageDropdown
            language={settings.rLocale}
            excludeLanguage={settings.lLocale}
            short={true}
            setLanguage={(value: string) =>
              setSettings({ ...settings, rLocale: value })
            }
          />
        </div>
      </div>
    </>
  );
}

export function SettingsModal({
  settings,
  setSettings,
  closeModal,
}: {
  settings: Settings;
  setSettings: (value: Settings) => void;
  closeModal: () => void;
}) {
  return (
    <Modal
      showCloseButton={true}
      locale={settings.lLocale}
      preferedWidth={300}
      closeModal={closeModal}
    >
      <div className="p-4">
        <h1 className="text-2xl font-semibold">
          {lstr(settings.lLocale).settings_title}
        </h1>
        <div className="mt-4">
          <SettingsMenu settings={settings} setSettings={setSettings} />
        </div>
      </div>
    </Modal>
  );
}
