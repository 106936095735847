import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import { StoryMenu, StoryMenuModal } from "./StoryMenu";
import { SettingsModal } from "./SettingsMenu";
import StoryView from "./StoryView";
import { Settings, Theme, ShowTranslationMode } from "./settings";
import { TopMenu } from "./TopMenu";

function AppComponent() {
  const { storyId } = useParams();
  const [currentStoryId, setCurrentStoryId] = useState<string | null>(null);
  const [showStoryList, setShowStoryList] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const navigate = useNavigate();

  const setSettingsSafe = (value: Settings) => {
    if (value.lLocale === value.rLocale) {
      const rLocale = value.lLocale === "en" ? "de" : "en";
      setSettings({ ...value, rLocale });
    } else {
      setSettings(value);
    }
  };

  const [settings, setSettings] = useState<Settings>({
    lLocale: "en",
    rLocale: "de",
    showTranslation: true,
    showTranslationMode: ShowTranslationMode.ByParagraph,
    theme: Theme.Light,
  });

  useEffect(() => {
    setCurrentStoryId(storyId ? storyId : null);
  }, [storyId]);

  const selectStory = (storyId: string) => {
    navigate(`/${storyId}`);
  };

  return (
    <>
      <div className="flex flex-col h-screen font-literata">
        {currentStoryId && !showSettings && showStoryList && (
          <StoryMenuModal
            l={settings.lLocale}
            r={settings.rLocale}
            currentStoryId={currentStoryId}
            onStorySelected={selectStory}
            closeModal={() => setShowStoryList(false)}
          />
        )}
        {showSettings && (
          <SettingsModal
            settings={settings}
            setSettings={setSettingsSafe}
            closeModal={() => setShowSettings(false)}
          />
        )}
        <div className="flex flex-col items-center w-full h-full bg-[#F8F3E6] overflow-auto">
          <TopMenu
            showStoryListButton={currentStoryId !== null}
            settings={settings}
            setSettings={setSettingsSafe}
            setShowSettings={() => setShowSettings(true)}
            setShowStoryList={() => setShowStoryList(true)}
          />
          <div className="max-w-[650px] pt-4 px-4">
            {!currentStoryId && (
              <StoryMenu
                l={settings.lLocale}
                r={settings.rLocale}
                currentStoryId={currentStoryId}
                onStorySelected={selectStory}
              />
            )}
            {currentStoryId && (
              <StoryView
                l={settings.lLocale}
                r={settings.rLocale}
                storyId={currentStoryId}
                shouldShowTranslation={settings.showTranslation}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:storyId?" Component={AppComponent}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
