export enum Theme {
  Dark = "dark",
  Light = "light",
}

export enum ShowTranslationMode {
  ByParagraph,
  BySentence,
}

export interface Settings {
  lLocale: string;
  rLocale: string;
  showTranslation: boolean;
  showTranslationMode: ShowTranslationMode;
  theme: Theme;
}
