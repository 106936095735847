function ShowTranslationCheckbox({
  isChecked,
  setIsChecked,
}: {
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
}) {
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="flex items-center space-x-2">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 accent-teal-600"
      />
      <label className="text-sm text-[#F8F3E6]">Show translation</label>
    </div>
  );
}

export default ShowTranslationCheckbox;
