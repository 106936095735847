import { useState } from "react";
import { Chapter, Paragraph, Sentence } from "./story";
import {
  apiUrl,
  useStoryQuery,
  useExplainQuery,
  NotFoundError,
} from "./queries";
import { lstr } from "./localization";
import { API_URL } from "./config";
import { Modal } from "./Modal";

function Image({ storyId, imageId }: { storyId: string; imageId?: string }) {
  if (!imageId) {
    return <></>;
  }
  let imageUrl = apiUrl("/image");
  imageUrl.searchParams.append("story_id", storyId);
  imageUrl.searchParams.append("id", imageId);
  return (
    <div className="relative w-full pb-[40%] overflow-hidden rounded-xl bg-yellow mb-4">
      <img
        alt=""
        src={imageUrl.toString()}
        className="absolute object-cover top-1/2 left-0 w-full h-auto -translate-y-1/2"
      />
    </div>
  );
}

function ExplanationModal({
  storyId,
  l,
  r,
  lSentence,
  rSentence,
  closeModal,
}: {
  storyId: string;
  l: string;
  r: string;
  lSentence: Sentence;
  rSentence: Sentence;
  closeModal: () => void;
}) {
  const query = useExplainQuery(
    storyId,
    l,
    r,
    lSentence.index,
    rSentence.index,
  );

  let audioUrl = new URL(`${API_URL}/audio`);
  audioUrl.searchParams.append("story_id", storyId);
  audioUrl.searchParams.append("locale", r);
  audioUrl.searchParams.append("sentence_idx", rSentence.index.toString());

  return (
    <Modal
      showCloseButton={true}
      locale={l}
      preferedWidth={500}
      closeModal={closeModal}
    >
      <div className="flex flex-col px-4 pt-4">
        <div className="text-xl font-semibold">{rSentence.text}</div>
        <div className="text font-semibold text-gray-600">{lSentence.text}</div>
        {lSentence.hasAudio && (
          <audio src={audioUrl.toString()} controls></audio>
        )}
        {query.isPending && (
          <div className="mt-4">{lstr(l).loading_explain}</div>
        )}
        {query.isError && (
          <div className="mt-4">{lstr(l).loading_explain_error}</div>
        )}
        {query.isSuccess && (
          <div
            className="mt-4 flex flex-col gap-4"
            dangerouslySetInnerHTML={{ __html: query.data }}
          ></div>
        )}
      </div>
    </Modal>
  );
}

function SentenceView({
  text,
  storyId,
  l,
  r,
  lSentence,
  rSentence,
  style,
  onHover,
}: {
  text: string;
  storyId: string;
  l: string;
  r: string;
  lSentence: Sentence;
  rSentence: Sentence;
  style: string;
  onHover: (hover: boolean) => void;
}) {
  const [showExplanation, setShowExplanation] = useState(false);

  const onClick = () => {
    setShowExplanation(true);
  };

  return (
    <>
      <span
        className={`select-none hover:bg-emerald-300 rounded px-[2px] py-[1px] transition-colors ${style}`}
        onClick={() => onClick()}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        {text}
      </span>
      {showExplanation && (
        <ExplanationModal
          storyId={storyId}
          l={l}
          r={r}
          lSentence={lSentence}
          rSentence={rSentence}
          closeModal={() => setShowExplanation(false)}
        />
      )}
    </>
  );
}

function ParagraphView({
  storyId,
  l,
  r,
  lParagraph,
  rParagraph,
  shouldShowTranslation,
}: {
  storyId: string;
  l: string;
  r: string;
  lParagraph: Paragraph;
  rParagraph: Paragraph;
  shouldShowTranslation: boolean;
}) {
  const [hoveredSentence, setHoveredSentence] = useState<number | null>(null);

  if (lParagraph.sentences.length !== rParagraph.sentences.length) {
    console.error("Paragraph sentences are not aligned");
    return (
      <div className="text-red-800">Paragraph sentences are not aligned</div>
    );
  }

  const onHover = (sentenceIdx: number, hover: boolean) => {
    if (hover) {
      setHoveredSentence(sentenceIdx);
    } else {
      setHoveredSentence(null);
    }
  };

  return (
    <div>
      <Image storyId={storyId} imageId={rParagraph.imageId} />
      <div>
        {rParagraph.sentences.map((sentence, index) => (
          <span key={index} className={index === 0 ? "ml-4" : ""}>
            <SentenceView
              text={sentence.text}
              storyId={storyId}
              l={l}
              r={r}
              lSentence={lParagraph.sentences[index]}
              rSentence={sentence}
              style={
                hoveredSentence === index
                  ? "text-base bg-emerald-300"
                  : "text-base"
              }
              onHover={(hover) => onHover(index, hover)}
            />
            {index < rParagraph.sentences.length - 1 && <span> </span>}
          </span>
        ))}
      </div>
      {shouldShowTranslation && (
        <div className="mt-3">
          {lParagraph.sentences.map((sentence, index) => (
            <span key={index} className={index === 0 ? "ml-4" : ""}>
              <SentenceView
                text={sentence.text}
                storyId={storyId}
                l={l}
                r={r}
                lSentence={sentence}
                rSentence={rParagraph.sentences[index]}
                style={
                  hoveredSentence === index
                    ? "text-base text-gray-600 bg-emerald-300"
                    : "text-base text-gray-600"
                }
                onHover={(hover) => onHover(index, hover)}
              />
              {index < rParagraph.sentences.length - 1 && <span> </span>}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

function ChapterView({
  storyId,
  l,
  r,
  lChapter,
  rChapter,
  shouldShowTranslation,
}: {
  storyId: string;
  l: string;
  r: string;
  lChapter: Chapter;
  rChapter: Chapter;
  shouldShowTranslation: boolean;
}) {
  if (lChapter.paragraphs.length !== rChapter.paragraphs.length) {
    console.error("Chapter paragraphs are not aligned");
    return (
      <div className="text-red-800">Chapter paragraphs are not aligned</div>
    );
  }

  const paragraphGap = shouldShowTranslation ? 8 : 4;

  return (
    <div>
      {rChapter.title && (
        <h2 className="text-xl font-bold text-center">{rChapter.title}</h2>
      )}
      <div className={`flex flex-col gap-${paragraphGap} mt-4`}>
        {rChapter.paragraphs.map((paragraph, index) => (
          <ParagraphView
            key={index}
            storyId={storyId}
            l={l}
            r={r}
            lParagraph={lChapter.paragraphs[index]}
            rParagraph={paragraph}
            shouldShowTranslation={shouldShowTranslation}
          />
        ))}
      </div>
    </div>
  );
}

function StoryView({
  storyId,
  l,
  r,
  shouldShowTranslation,
}: {
  storyId: string;
  l: string;
  r: string;
  shouldShowTranslation: boolean;
}) {
  const query = useStoryQuery(storyId, l, r);

  if (query.isPending) {
    return (
      <div className="w-full p-4 overflow-auto">{lstr(l).loading_story}</div>
    );
  }

  if (query.isError) {
    return (
      <div className="w-full p-4 overflow-auto font-semibold text-[22px]">
        {query.error instanceof NotFoundError
          ? lstr(l).story_not_found_error
          : lstr(l).loading_story_error}
      </div>
    );
  }

  const lStory = query.data.localizations.get(l)!;
  const rStory = query.data.localizations.get(r)!;

  if (lStory.chapters.length !== rStory.chapters.length) {
    console.error("Story paragraphs are not aligned");
    return <div className="text-red-800">Story paragraphs are not aligned</div>;
  }

  return (
    <div>
      <Image storyId={storyId} imageId={rStory.imageId} />
      <h1 className="text-2xl font-bold text-center">{rStory.title}</h1>
      {shouldShowTranslation && (
        <h1 className="text-2xl font-bold text-gray-500 text-center">
          {lStory.title}
        </h1>
      )}
      <div className="mt-8">
        <div className="flex flex-col gap-8 pb-10">
          {rStory.chapters.map((chapter, index) => (
            <ChapterView
              key={index}
              storyId={storyId}
              l={l}
              r={r}
              lChapter={lStory.chapters[index]}
              rChapter={chapter}
              shouldShowTranslation={shouldShowTranslation}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default StoryView;
