import { useQuery } from "@tanstack/react-query";
import { API_URL } from "./config";
import { StoryIdAndTitle, StoryMultilingual } from "./story";

export function apiUrl(path: string) {
  if (!path.startsWith("/") || path.includes("..")) {
    throw new Error("Invalid path");
  }
  return new URL(`${API_URL}${path}`);
}

export function useStoryListQuery(l: string, r: string) {
  let url = new URL(`${API_URL}/story-list`);
  url.searchParams.append("l", l);
  url.searchParams.append("r", r);

  return useQuery<StoryIdAndTitle[]>({
    queryKey: ["story-list", l, r],
    queryFn: () =>
      fetch(url, {
        method: "GET",
        credentials: "include",
      }).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.error("Unexpected result for", url);
          console.error(res);
          throw new Error("Unexpected result for story-list query");
        }
      }),
  });
}

export class NotFoundError {}

export function useStoryQuery(storyId: string, l: string, r: string) {
  let url = new URL(`${API_URL}/story`);
  url.searchParams.append("id", storyId);
  url.searchParams.append("l", l);
  url.searchParams.append("r", r);

  return useQuery<StoryMultilingual>({
    queryKey: ["story", storyId, l, r],
    queryFn: () =>
      fetch(url, {
        method: "GET",
        credentials: "include",
      }).then((res) => {
        if (res.status === 200) {
          return res.json().then((obj) => {
            return {
              id: obj.id,
              localizations: new Map(Object.entries(obj.localizations)),
            };
          });
        } else if (res.status === 404) {
          throw new NotFoundError();
        } else {
          console.error("Unexpected result for", url);
          console.error(res);
          throw new Error("Unexpected result for story query");
        }
      }),
    refetchInterval: 5000, // TODO: remove this in PROD
    retry: false,
  });
}

export function useExplainQuery(
  storyId: string,
  l: string,
  r: string,
  lSentenceIdx: number,
  rSentenceIdx: number,
) {
  let url = new URL(`${API_URL}/explain`);
  url.searchParams.append("l_sentence_idx", lSentenceIdx.toString());
  url.searchParams.append("r_sentence_idx", rSentenceIdx.toString());
  url.searchParams.append("story_id", storyId);
  url.searchParams.append("l", l);
  url.searchParams.append("r", r);

  return useQuery<string>({
    queryKey: ["explain", storyId, l, r, lSentenceIdx, rSentenceIdx],
    queryFn: () =>
      fetch(url, {
        method: "GET",
        credentials: "include",
      }).then((res) => {
        if (res.status === 200) {
          return res.json().then((obj) => {
            return obj.content;
          });
        } else {
          console.error("Unexpected result for", url);
          console.error(res);
          throw new Error("Unexpected result for explain query");
        }
      }),
  });
}
